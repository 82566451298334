import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import './Login.css';

const Login = (props) => {
    const [isLoginVisible, setIsLoginVisible] = useState(!props.register);
    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmation, setConfirmation] = useState("");

    const login = async () => {
        const response = await axios.get('https://unbot.io/api/v1.0/auth', {
            params: { mail, password }
        });

        handleAuthResponse(response);
    }

    const register = async () => {
        if (password !== confirmation) {
            alert("Le mot de passe et la confirmation sont différents");
            return
        }

        const response = await axios.post('https://unbot.io/api/v1.0/auth', {
            mail,
            password
        });

        handleAuthResponse(response);
    }

    const handleAuthResponse = (response) => {
        if (!response.data) {
            return;
        }

        if (response.data.error) {
            alert(response.data.error.message);
            return;
        }

        let { jwt } = response.data;

        Cookies.set('user_token', jwt, { expires: 365 });
        props.onLogin();
    }

    const handleMailChanged = (e) => {
        setMail(e.target.value);
    }

    const handlePasswordChanged = (e) => {
        setPassword(e.target.value);
    }

    const handleConfirmationChanged = (e) => {
        setConfirmation(e.target.value);
    }

    return (
        <div className="login-page">
            <div className="login-container">
                <h1 className="login-title">Bienvenue</h1>
                {
                    isLoginVisible &&
                    <>
                        <p className="login-subtitle">connecte-toi à ton compte !</p>

                        <input
                            type="email"
                            className="text-input"
                            placeholder="Entre ton email"
                            value={mail}
                            onChange={handleMailChanged}
                        />

                        <input
                            type="password"
                            className="text-input"
                            placeholder="Mot de passe"
                            value={password}
                            onChange={handlePasswordChanged}
                        />

                        <button
                            className="next-button"
                            onClick={login}
                        >
                            Suivant
                        </button>

                        <p
                            onClick={() => setIsLoginVisible(false)}
                            className="account-text">
                            Je n'ai pas de compte
                        </p>
                    </>
                }

                {
                    !isLoginVisible &&
                    <>
                        <p className="login-subtitle">créé ton compte !</p>

                        <input
                            type="email"
                            className="text-input"
                            placeholder="Entre ton email"
                            value={mail}
                            onChange={handleMailChanged}
                        />

                        <input
                            type="password"
                            className="text-input"
                            placeholder="Mot de passe"
                            value={password}
                            onChange={handlePasswordChanged}
                        />

                        <input
                            type="password"
                            className="text-input"
                            placeholder="Confirmation"
                            value={confirmation}
                            onChange={handleConfirmationChanged}
                        />

                        <button
                            className="next-button"
                            onClick={register}
                        >
                            Je crée mon compte
                        </button>

                        <p
                            onClick={() => setIsLoginVisible(true)}
                            className="account-text">
                            J'ai déjà un compte
                        </p>
                    </>
                }
            </div>
        </div>
    );
};

export default Login;
