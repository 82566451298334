import React, { useEffect, useState } from 'react';
import './App.css';

import Cookies from 'js-cookie';
import Features from './components/Features';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import Login from './components/Login';
import Pricing from './components/Pricing';
import Profile from './components/Profile';
import Testimonial from './components/Testimonial';
import TextComponent from './components/TextComponent';

function App() {
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [registerMode, setRegisterMode] = useState(false);

  useEffect(() => {
    const jwt = Cookies.get('user_token');

    if (jwt) {
      handleLogin();
    }
  });

  const handleLoginButtonClick = () => {
    setRegisterMode(false);
    setIsLoginVisible(true);
  };

  const handleRegisterButtonClick = () => {
    setRegisterMode(true);
    setIsLoginVisible(true);
  };

  const handleHomeButtonClick = () => {
    const jwt = Cookies.get('user_token');

    if (!jwt) {
      setIsLogged(false);
      setIsLoginVisible(false);
    }
  };

  const handleLogin = () => {
    setIsLogged(true);
  };

  const handleLogout = () => {
    Cookies.remove('user_token');
    setIsLogged(false);
    setIsLoginVisible(false);
  };

  return (
    <div className="App">
      <Header
        key={isLogged}
        onLoginButtonClicked={handleLoginButtonClick}
        onHomeButtonClicked={handleHomeButtonClick}
        isLogged={isLogged}
      />

      {
        isLogged &&
        <Profile
          onLogoutButtonClicked={handleLogout}
        />
      }

      {
        !isLogged &&
        <>
          {isLoginVisible ? (
            <Login
              register={registerMode}
              onLogin={handleLogin}
            />
          ) : (
            <>
              <Hero
                onRegisterButtonClicked={handleRegisterButtonClick}
              />
              <Features
                onRegisterButtonClicked={handleRegisterButtonClick}
              />
              <TextComponent
                onRegisterButtonClicked={handleRegisterButtonClick}
              />
              <Testimonial />
              <Pricing
                onRegisterButtonClicked={handleRegisterButtonClick}
              />
            </>
          )}

          {/* <Footer /> */}
        </>
      }
    </div>
  );
}

export default App;
