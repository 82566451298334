import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import './PaymentForm.css';

const PaymentForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardholderName, setCardholderName] = useState('');

    const handleSubmit = async (event) => {
        props.onPaymentInitialized();

        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setError(error.message);
            console.log("Error", error);
        } else {
            setError(null);
            console.log(paymentMethod.id);
            props.onPaymentSuccessfull(cardholderName, paymentMethod.id);
        }
    };

    const cardElementOptions = {
        hidePostalCode: true
    };

    return (
        <div style={{
            width: "400px",
            height: "auto",
            textAlign: "center"
          }}>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    className="text-input"
                    placeholder="Nom du titulaire de la carte"
                    value={cardholderName}
                    onChange={(e) => setCardholderName(e.target.value)}
                    required
                />

                <div className="card-element-wrapper">
                    <div className="card-number">
                        <CardElement options={cardElementOptions} />
                    </div>
                </div>

                {error && <p style={{ color: 'red' }}>{error}</p>}

                <button
                    type="submit"
                    disabled={!stripe}
                    className="new-bot-button"
                >
                    Profiter de l’essai gratuit
                </button>
            </form>
        </div>
    );
};

export default PaymentForm;
