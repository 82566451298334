import React from 'react';
import './TextComponent.css'; // Ensure this path matches your file structure

const TextComponent = (props) => {
    return (
        <section className="text-section">
            <h1 className="text-title">Libérez votre temps avec des réponses automatiques intelligentes !</h1>
            <p className="text-description">
                Notre chatbot génère des réponses automatiques en un instant, vous permettant de vous concentrer sur ce que vous aimez faire le plus : créer du contenu. Dites adieu aux heures passées à répondre aux messages !
            </p>
            <button
                onClick={props.onRegisterButtonClicked}
                className="cta-button"
            >
                Essayer gratuitement
            </button>
        </section>
    );
};

export default TextComponent;
