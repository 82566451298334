import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe('pk_live_51QDSzqC72cGBqN4gLFHS68mwQ6nYlzZw7rL7pI7rWPBdZaGu4f10BHPPkvtH5U9N6Tsz30jY1GYKKxZAj9z2BbN800WsUZs0eQ');

// Stripe test public key
//pk_test_51QDSzqC72cGBqN4gLtTwaKxM7muUavBYYpZzPsYdJqIMav4GkwkChY4dlyPlfzPFnDQNnO9QNOR5Op56NTh4ERNq00Tk6c8Nue

const StripeProvider = ({ children }) => {
    return (
        <Elements stripe={stripePromise}>
            {children}
        </Elements>
    );
};

export default StripeProvider;