import React from 'react';
import './Testimonial.css';

function Testimonial() {
  return (
    <section className="testimonial">
      <blockquote>
        “Ce chatbot a amélioré la façon dont j'interagis avec mes fans. Les réponses automatiques sont une révolution !”
      </blockquote>
    </section>
  );
}

export default Testimonial;