import React from 'react';
import './Pricing.css'; // Ensure this path matches your file structure

const Pricing = (props) => {
    return (
        <section className="pricing-section">
            <div className="pricing-card">
                <p className="pricing-title">Premium</p>
                <ul className="pricing-features">
                    <li>✓ Nombre de messages illimités</li>
                    <li>✓ Interactions personnalisées</li>
                    <li>✓ Réponse en 1 clic</li>
                </ul>
                <p className="pricing-price">12<span className="pricing-currency">€ /mois</span></p>
                <button
                    onClick={props.onRegisterButtonClicked}
                    className="cta-button-pricing"
                >
                    7 JOURS GRATUIT
                </button>
            </div>
            <div className="pricing-description">
                <h2 className="description-title">Essaye Unbot dès maintenant et profite de 7 jours gratuit sans engagement</h2>
            </div>
        </section>
    );
};

export default Pricing;
