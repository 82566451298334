import React from 'react';
import './Hero.css';

import webStoreChrome from "../img/web-store-chrome.png";
import demoVideo from "../img/demo.mp4";

const Hero = (props) => {
  return (
    <section className="hero-section">
      <div className="hero-section-content">
        <h1 className="hero-section-title">Créez des interactions authentiques, sans effort.</h1>
        <p className="hero-section-subtitle">
          Gagnez du temps et boostez votre engagement avec des réponses instantanées.
        </p>

        <div className="hero-video-container">
          <video
            className="hero-video"
            src={demoVideo}
            controls={false}
            loop={true}
            muted={true}
            autoPlay={true}
          />
        </div>

        <div className="hero-section-buttons">
          <div className="hero-chrome-store">
            <img
              alt="Available in the Chrome Web Store"
              src={webStoreChrome}
            />
          </div>
          <button
            onClick={props.onRegisterButtonClicked}
            className="cta-button"
          >
            Essayer gratuitement, dès maintenant
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
