import React from 'react';
import './Features.css';
import generateButton from '../img/generate-button.png';

const Feature = (props) => {
    return (
        <section className="feature-section">
            <div className="feature-left">
                <div className="message-bubble first-bubble">
                    <p>Ah trop bien, j'ai hâte de voir ça ! D'ailleurs, je me demandais, comment tu trouves l'inspiration pour tes vidéos ? Tu as toujours des idées super originales !</p>
                </div>
                <button className="generate-button">
                    <img src={generateButton} alt="Générer un message par IA" />
                </button>
                <div className="message-bubble second-bubble">
                    <p>C'est génial que tu remarques ça ! 😊 L'inspiration me vient souvent en échangeant avec des gens comme toi, mais aussi en observant ce qui m'entoure au quotidien. J'aime beaucoup créer en fonction de mon humeur du moment aussi, ça me permet de rester authentique !</p>
                </div>
            </div>
            <div className="feature-right">
                <h2 className="feature-title">Toute la discussion en 1 clic</h2>
                <p className="feature-description">
                    Unbot est conçu pour comprendre et répondre à vos fans de manière instantanée et personnalisée. Imaginez pouvoir engager vos abonnés à tout moment, même lorsque vous êtes occupé à créer vos prochains contenus.
                </p>
                <button
                    onClick={props.onRegisterButtonClicked}
                    className="cta-button"
                >
                    Essayer gratuitement
                </button>
            </div>
        </section>
    );
};

export default Feature;
