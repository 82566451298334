import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import PaymentForm from './PaymentForm';
import CircularProgress from '@mui/material/CircularProgress';

import './Profile.css';

const Profile = (props) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [count, setCount] = useState(0);
  const [newBotMode, setNewBotMode] = useState(false);
  const [uncoveName, setUncoveName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchSubscriptionData();
  }, []);

  const fetchSubscriptionData = async () => {
    const jwt = Cookies.get('user_token');

    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    };

    try {
        const response = await axios.get('https://unbot.io/api/v1.0/subscription', config);
        console.log("response", response.data);

        setSubscriptions(response.data);
        setCount(response.data.length);
    } catch (error) {
        console.error("Error fetching subscription data:", error);
    }
  };

  const calculateDaysSinceSubscribed = (date) => {
    const subscriptionDate = new Date(date);
    const currentDate = new Date();
    const differenceInTime = currentDate - subscriptionDate;
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
  }

  const calculateDaysUntilRenewal = (date) => {
    const subscriptionDate = new Date(date);
    const now = new Date();
    const nextRenewalDate = new Date();
    nextRenewalDate.setDate(subscriptionDate.getDate());
    if (nextRenewalDate <= now) {
      nextRenewalDate.setMonth(nextRenewalDate.getMonth() + 1);
    }

    const currentDate = new Date();

    if (currentDate.toDateString() === nextRenewalDate.toDateString()) {
        return 0;
    }

    const differenceInTime = nextRenewalDate - currentDate;
    return Math.ceil(differenceInTime / (1000 * 3600 * 24));
  }

  const stopSubscription = async (subscriptionId) => {
    const jwt = Cookies.get('user_token');

    const config = {
      headers: {
          Authorization: `Bearer ${jwt}`
      }
    };

    setIsLoading(true);

    try {
      await axios.delete('https://unbot.io/api/v1.0/subscription', {
        ...config,
        data: {
          subscriptionId: subscriptionId
        }
      });

      fetchSubscriptionData();

      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting subscription:", error);
    }
  }

  const handleUncoveNameChanged = (e) => {
    setUncoveName(e.target.value);
  }

  const addBot = async (cardholderName, paymentMethodId) => {
    const jwt = Cookies.get('user_token');

    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    };

    try {
      let response = await axios.post('https://unbot.io/api/v1.0/subscription',
        {
          userName: uncoveName,
          cardholderName,
          paymentMethodId
        },
        config
      );

      setIsLoading(false);

      if (response.data.error) {
        alert(response.data.error.message);
        return;
      }

      fetchSubscriptionData();
      setUncoveName("");
      setNewBotMode(false);
    } catch (error) {
      console.log("Error", error);
    }
  }

  return (
    <div className="profile-container">
      {
        isLoading &&
        <div className="loading-container">
          <div className="loading-box">
            <CircularProgress color="primary" />
          </div>
        </div>
      }

      {
        !newBotMode && <>
          <p className="profile-title">Mon profil</p>
          <button
            className="new-bot-button"
            onClick={() => setNewBotMode(true)}
          >
            Prendre un nouveau bot pour un compte
          </button>

          <p className="profile-subtitle">Mes abonnements ({count})</p>

          {
            subscriptions.map(subscription => {
              const daysSubscribed = calculateDaysSinceSubscribed(subscription.date);
              const daysUntilRenewal = calculateDaysUntilRenewal(subscription.date);

              return <div className="subscription">
                <span className="subscription-name">{subscription?.creator?.name ?? ""}</span>
                <span className="subscription-details">abonné depuis {daysSubscribed} jours</span>
                <span className="renewal-info">Renouvellement dans {daysUntilRenewal} jours</span>
                <button
                  className="button-outline"
                  onClick={() => stopSubscription(subscription._id)}
                >
                  Arrêter le bot
                </button>
              </div>
            })
          }

          <button
            className="logout-button"
            onClick={props.onLogoutButtonClicked}
          >
            Je me déconnecte
          </button>
        </>
      }

      {
        newBotMode &&
        <div className="new-bot-container">
          <p className="new-bot-title">Choisis le compte Uncove</p>

          <input
              type="text"
              className="text-input"
              placeholder="Nom du créateur Uncove"
              value={uncoveName}
              onChange={handleUncoveNameChanged}
          />

          <p className="new-bot-title">Moyen de paiement</p>

          <PaymentForm
            onPaymentInitialized={() => setIsLoading(true)}
            onPaymentSuccessfull={addBot}
          />

          <button
            className="button-outline"
            onClick={() => setNewBotMode(false)}
          >
            Retour
          </button>
        </div>
      }
    </div>
  );
};

export default Profile;
