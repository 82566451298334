import React, { useState } from 'react';

import './Header.css';

import loginButton from "../img/login-button.png";
import logo from "../img/logo.png";

function Header(props) {
  const [isLogged, setIsLogged] = useState(props.isLogged);

  return (
    <header className="header">
      <div
        onClick={props.onHomeButtonClicked}
        className="brand-row"
      >
        <img className="logo" src={logo} />
        <label className="brand">Unbot</label>
      </div>

      {
        !isLogged &&
        <div
          className="user-icon"
          onClick={props.onLoginButtonClicked}
        >
          <img src={loginButton} />
        </div>
      }
    </header>
  );
}

export default Header;
